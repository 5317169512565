import React from "react"
import {connect} from 'react-redux'
import {filterSubstring} from "../helper_functions/table_filtering";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import Tooltip from "@material-ui/core/Tooltip"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"
import Button from "components/CustomButtons"
import Card from "components/Card"
import CardBody from "components/CardBody"
import CardHeader from "components/CardHeader"

import extendedTablesStyle from "./extendedTablesStyle.jsx"

import {systemService} from "_services"

import {sortObj} from "views/helper_functions/sorting";
import {beautify_name} from "../helpers";
import {Brightness1TwoTone, Close, CloseTwoTone, DoneTwoTone, Info, Wifi} from "@material-ui/icons";
import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import Sunny from "@material-ui/icons/WbSunnyTwoTone";
import {Link} from "react-router-dom";
import MyReactTable from "../../components/MyReactTable/MyReactTable";

import ReactTimeAgo from 'react-time-ago'
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import queryString from "query-string";
import ClipboardButton from "../../components/ClipboardButoon";
import {get_battery_icon, get_connection_icon} from "../helper_functions/device_icons";


class StateFleet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      state_overview: [],
      state_page: 0,
      last_updated: null,
      loading: false,
      filter_device_serial: null,
      filter_error: false,
      filter_operational: false,
      filter_treatment: false
    }

    this.timer = null
    this._is_mounted = false
    this._refresh_rate = 3000
  }

  on_page_change(new_page) {
    this.setState({state_page: new_page})
  }

  componentDidMount() {
    this._is_mounted = true
    this.setState({loading: true})

    const {filter_device_serial} = queryString.parse(this.props.location.search)
    let my_device_serial_filteral = null

    if (filter_device_serial !== undefined && filter_device_serial !== null) {
      my_device_serial_filteral = filter_device_serial.split(";")
    }
    this.setState({
      filter_device_serial: my_device_serial_filteral
    }, () => {
      this.refresh()
    })
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this._is_mounted = false
  }

  refresh() {
    const {all_devices} = this.props
    const {filter_device_serial, filter_error, filter_operational, filter_treatment} = this.state
    systemService.getStateOverview(
      result => {
        let devices = sortObj(result.result, 'id').filter(function (device) {
          let device_state = device["state"]["value"] ? device["state"]["value"].toLowerCase() : "error" || "error"
          if (!device.is_live.value) {
            device_state = "-"
          }
          if (filter_device_serial !== null) {
            return filter_device_serial.indexOf(device.device_serial) > -1
          }
          if (filter_error) {
            return device.error_code.value !== "0x00000000" && device.error_code.value !== null && device.is_live.value;
          }
          if (filter_operational) {
            let device_info = {}
            all_devices.forEach((dev_) => {
              if (dev_.serial === device.device_serial) {
                device_info = dev_
              }
            })
            return device_info.life_cycle_stage === "OPERATIONAL"
          }

          if (filter_treatment) {
            return (device_state !== "idle" && device_state !== "error" && device_state !== "manual driving" && device_state !== "-" && device_state !== "unknown" && device.is_live.value)
          }
          return true
        })

        let test2 = devices.map((device, key) => {
          return {
            id: key,
            ...device,
            actions: <Link to={`/device/detail?device_serial=${device["device_serial"]}`}><Info
              fontSize="small"/> Info</Link>,
            actions_b: <Link to={`/device/commands?device_serial=${device["device_serial"]}`}><Sunny
              fontSize="small"/> Actions</Link>
          }
        })

        this.setState({state_overview: test2, loading: false, last_updated: new Date(result.updated)})
        if (this._is_mounted) {
          this.timer = setTimeout(() => this.refresh(), this._refresh_rate)
        }
      },
      reject => {
        this.setState({state_overview: [], loading: false})
        if (this._is_mounted) {
          this.timer = setTimeout(() => this.refresh(), this._refresh_rate * 2)
        }
      }
    )
  }

  clear_filters() {
    this.setState({
      filter_device_serial: null,
      filter_error: false,
      filter_operational: false,
      filter_treatment: false
    }, () => this.refresh())
  }

  filter_error() {
    this.setState({
      filter_error: true,
      filter_operational: false,
      filter_device_serial: null,
      filter_treatment: false
    }, () => this.refresh())
  }

  filter_operational() {
    this.setState({
      filter_operational: true,
      filter_error: false,
      filter_device_serial: null,
      filter_treatment: false
    }, () => this.refresh())
  }

  filter_in_treatment() {
    this.setState({
      filter_treatment: true,
      filter_device_serial: null,
      filter_error: false,
      filter_operational: false
    }, () => this.refresh())
  }

  render() {
    const {classes, error_codes, locations} = this.props
    const {
      state_overview,
      loading,
      state_page,
      last_updated,

    } = this.state
    const {filter_device_serial, filter_error, filter_operational, filter_treatment} = this.state

    let columns = [
      {
        Header: <Wifi fontSize="small" style={{color: "rgb(173,170,170)"}}/>,
        accessor: "is_live.value",
        Cell: row => (
          get_connection_icon(classes, row.value, row.original.last_seen, row.original.has_wifi.value)
        ),
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }

          if (filter.value === "online") {
            return row["is_live.value"] === true;
          }

          if (filter.value === "offline") {
            return row["is_live.value"] === false;
          }

          return false;
        },

        Filter: ({filter, onChange}) =>
          <FormControl fullWidth className={classes.selectFormControl}>
            <Select
              onChange={event => {
                onChange(event.target.value)
              }}
              style={{width: "100%"}}
              value={filter ? filter.value : "all"}
            >
              <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                        value={'all'}>
                {"all"}
              </MenuItem>
              <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                        value={'online'}>
                <Brightness1TwoTone fontSize="small" style={{color: "rgb(127, 175, 12)"}}/>
              </MenuItem>
              <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                        value={'offline'}>
                <Brightness1TwoTone fontSize="small" style={{color: "rgb(175,12,12)"}}/>
              </MenuItem>
            </Select>
          </FormControl>,
        width: 50,
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: row => (
          <div><ClipboardButton text={row.original.device_serial}/><b>{row.value}</b></div>
        ),
        filterMethod: (filter, row) => filterSubstring(filter, row, "name")
      },
      {
        Header: "Location",
        accessor: "location_id.value",
        filterMethod: (filter, row) => filterSubstring(filter, row),
        Cell: row => {
          return locations && locations[row.value] ? locations[row.value].name : row.value
        }
      },
      {
        Header: "Current Task",
        accessor: "state.value",
        Cell: row => {
          if (row.value === null || row.value === undefined) {
            return "-"
          }
          if (!row.original.is_live.value) {
            return "-"
          }
          return row.value.toLowerCase() === 'error' ?
            <span
              style={{color: 'rgb(255,0,0)'}}>
                            {beautify_name(row.value)}
              <Tooltip
                id="tooltip2"
                title={error_codes[row.original.error_code.value] ? error_codes[row.original.error_code.value]["development"] : "unknown"}
                placement="right"
                classes={{tooltip: classes.tooltip}}
              >
                                <p>{row.original.error_code.value}</p>
                            </Tooltip>
                        </span> :
            row.value.toLowerCase() === "unknown" ? "-" :
              <p>{beautify_name(row.value)}</p>
        },
        filterMethod: (filter, row) => filterSubstring(filter, row)
      },
      {
        Header: "Maps Valid?",
        accessor: "all_maps_valid.value",
        width: 120,
        sortable: false,
        Cell: row => {
          if (row.value !== true && row.value !== false) {
            return "-"
          }

          return <span style={{color: row.value ? "rgb(100,169,68)" : "rgb(231,92,92)"}}>
                        {
                          row.value ? <DoneTwoTone/> : <CloseTwoTone/>
                        }
                    </span>

        },
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }

          if (filter.value === "unknown") {
            return row["all_maps_valid.value"] !== true && row["all_maps_valid.value"] !== false;
          }

          if (filter.value === "valid") {
            return row["all_maps_valid.value"] === true;
          }

          if (filter.value === "invalid") {
            return row["all_maps_valid.value"] === false;
          }

          return false;
        },

        Filter: ({filter, onChange}) =>
          <FormControl fullWidth className={classes.selectFormControl}>
            <Select
              onChange={event => onChange(event.target.value)}
              style={{width: "100%"}}
              value={filter ? filter.value : "all"}
            >
              <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                        value={'all'}>
                {"all"}
              </MenuItem>
              <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                        value={'unknown'}>
                {"unknown"}
              </MenuItem>
              <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                        value={'valid'}>
                                <span style={{color: "rgb(100,169,68)"}}>
                        <DoneTwoTone/>
                    </span>
              </MenuItem>
              <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                        value={'invalid'}>
                               <span style={{color: "rgb(231,92,92)"}}>
                        <CloseTwoTone/>
                    </span>
              </MenuItem>
            </Select>
          </FormControl>,
      },
      {
        Header: "Battery",
        accessor: "battery_level.value",
        width: 120,
        filterable: false,
        Cell: row => (get_battery_icon(row.value))
      },
      {Header: "", accessor: "actions", sortable: false, filterable: false, width: 100},
      {Header: "", accessor: "actions_b", sortable: false, filterable: false, width: 100}
    ]

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader>
              <GridContainer>
                <GridItem xs={6}>
                  <h4 style={{color: primaryColor}}>
                    <b>{"Overview All Devices"}</b>
                    {last_updated !== null ?
                      <p>Last update: <ReactTimeAgo date={last_updated} locale="en-UK"/>
                      </p> : ""}
                  </h4>

                </GridItem>
                <GridItem xs={6} style={{textAlign: "right"}}>
                  <Button simple
                          color={null == filter_device_serial && filter_treatment === false && filter_error === false && filter_operational === false ? "gray" : "danger"}
                          onClick={this.clear_filters.bind(this)}
                          disabled={null == filter_device_serial && filter_treatment === false && filter_error === false && filter_operational === false}>
                    <Close/> Clear Filter
                  </Button>
                  <Button simple color={"primary"} onClick={this.filter_operational.bind(this)}>
                    {filter_operational ? <b>Operational Devices</b> : <span>Operational Devices</span>}
                  </Button>
                  <Button simple color={"primary"} onClick={this.filter_error.bind(this)}>
                    {filter_error ? <b>Devices in Error</b> : <span>Devices in Error</span>}
                  </Button>
                  <Button simple color={"primary"} onClick={this.filter_in_treatment.bind(this)}>
                    {filter_treatment ? <b>Devices in treatment</b> :
                      <span>Devices in treatment</span>}
                  </Button>
                </GridItem>

              </GridContainer>

            </CardHeader>
            <CardBody>
              <MyReactTable
                id={"state_overview"}
                data={state_overview.sort((a, b) => {
                  return a["name"] < b["name"] ? -1 : 1
                })}
                filterable
                columns={columns}
                getTrProps={(state, rowInfo, column) => {
                  if (rowInfo && rowInfo.row) {
                    return {
                      style: {
                        background: rowInfo.row._original.last_seen === "" ? "rgba(255, 0, 0, 0.05)" : null
                      }
                    }
                  } else {
                    return {}
                  }
                }}
                defaultPageSize={10}
                page={state_page}
                onPageChange={this.on_page_change.bind(this)}
                showPaginationTop={false}
                showPaginationBottom={true}
                className=" -highlight"
                loading={loading}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}


function mapStateToProps(state) {
  const {all_devices} = state.device_fleet
  const {error_codes} = state.device_fleet
  const {locations} = state.location
  return {
    error_codes, locations, all_devices
  }
}


export default withStyles(extendedTablesStyle)(connect(mapStateToProps)(StateFleet))
