import React from "react"
import {connect} from 'react-redux'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"
import Clearfix from "components/Clearfix"
import Card from "components/Card"
import CardBody from "components/CardBody"
import CardHeader from "components/CardHeader"
import CardIcon from "components/CardIcon"
import Table from "components/Table"

// assets
import userProfileStyles from "./userProfileStyles.jsx"

// Services

class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }


  render() {
    const {classes, user} = this.props;

    let my_user = {
      "id": "",
      "email": "",
      "groups": ""
    }
    let groupStr = ""
    groupStr = user.profile["cognito:groups"].filter((group) => group.indexOf("user_type") >= 0).toString()

    my_user["email"] = user.profile.email
    my_user["groups"] = toUpperEveryWord(groupStr.substring(groupStr.indexOf(":") + 1).replace('_', ' '))
    my_user["id"] = user.profile.aud

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="primary" icon>
                  <CardIcon color="primary">
                    <PermIdentity/>
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>
                    {"Profile"}
                  </h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <Table
                        tableHeaderColor="primary"
                        style={{fontSize: '10px'}}
                        tableData={[
                          [<b>{"Mail Address"}</b>, my_user["email"]],
                          [<b>{"User Type"}</b>, my_user["groups"]],
                          ["", ""]
                        ]}
                      />
                    </GridItem>
                  </GridContainer>
                  <Clearfix/>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    )
  }
}

function mapStateToProps(state) {
  const {user} = state.authentication;
  return {
    user
  }
}

function toUpperEveryWord(str) {
  let strArray = str.split(" ");
  strArray = strArray.map((word) => {
    return "" + word.substring(0,1).toUpperCase() + word.substring(1)
  })
  return strArray.join(" ")
}


export default withStyles(userProfileStyles)(connect(mapStateToProps)(Account))
