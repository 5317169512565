import React, {useEffect, useState} from "react"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// Assets
import userProfileStyles from "./userProfileStyles.jsx"
import CardHeader from "../../components/CardHeader";
import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import GridContainer from "../../components/GridContainer";
import GridItem from "../../components/GridItem";
import Card from "../../components/Card";
import CardBody from "../../components/CardBody";
import {deviceService, managementService} from "../../_services";
import {filterSubstring} from "../helper_functions/table_filtering";
import MyReactTable from "../../components/MyReactTable/MyReactTable";
import {connect} from "react-redux";
import NavPills from "../../components/NavPills";
import ReactTable from "../../components/react-table";
import {beautify_name} from "../helpers";
import {Link} from "react-router-dom";
import {CloudUploadTwoTone, LibraryAddCheckTwoTone, BugReportTwoTone, SettingsTwoTone, FitnessCenterTwoTone, PauseCircleFilledTwoTone, CancelPresentationTwoTone, PresentToAllTwoTone} from "@material-ui/icons";
// deployment, operational, testing, service, production, pre-deployment, decommissioned, commissioned

const DeviceActions = ({device_serial, action_type, custom_action, classes}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalResults, setTotalResults] = useState(0);

  const fetchData = (page, pageSize) => {
    setLoading(true);

    deviceService.getDeviceActions(device_serial, action_type, pageSize, page - 1, result => {
      const total_pages = Math.ceil(result.total / pageSize)
      setTotalResults(result.total)
      if (currentPage > total_pages && result.total > 0) {
        setCurrentPage(total_pages)
      }
      setData(result.actions)
      setTotalPages(total_pages)
      setLoading(false)
    }, error => {
      setData([])
      setTotalPages(0)
      setLoading(false)
    });
  }

  useEffect(() => {
    fetchData(currentPage, pageSize)
  }, [currentPage, pageSize, device_serial, action_type])


  let filler_size = (currentPage - 1) * pageSize
  if (filler_size < 0) {
    filler_size = 0
  }
  let filler_array = Array(filler_size).keys()

  return (
    <>
      <h5># Results: {totalResults}</h5>
      <ReactTable
        columns={
          [
            {
              Header: "Device Serial",
              accessor: "device_serial",
            },
            {
              Header: "Type",
              accessor: "action_type",
              Cell: row => {
                return beautify_name(row.value)
              }
            },
            {
              Header: "Time",
              accessor: "timestamp",
              Cell: row => {
                return new Date(row.value).toLocaleString("nl-BE")
              },
            },
            {
              Header: "Done By",
              accessor: "done_by",
            },
            {
              Header: "Old Value",
              accessor: "action.old_value",
              Cell: row => {
                if (typeof row.value == "object" && row.value !== null)
                  return JSON.stringify(row.value);

                return row.value
              }
            },
            {
              Header: "New Value",
              accessor: "action.new_value",
              Cell: row => {
                if (typeof row.value == "object" && row.value !== null)
                  return JSON.stringify(row.value);

                return row.value
              }
            }
          ]}
        data={[
          ...filler_array,
          ...data
        ]}
        sortable={false}
        filterable={false}
        loading={loading}
        pages={totalPages}
        page={currentPage - 1}
        pageSize={pageSize}
        onPageChange={(pageIndex) => setCurrentPage(pageIndex + 1)}
        onPageSizeChange={(newPageSize) => {
          setPageSize(newPageSize);
          setCurrentPage(1);
        }}
      />
    </>
  );
};


class DeviceManagement extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
    this._is_mounted = false
    this.timer = null
  }

  componentDidMount() {
    this._is_mounted = true
    this.refresh()
  }

  componentWillUnmount() {
    this._is_mounted = false
    clearTimeout(this.timer)
    this.timer = null
  }

  refresh() {
  }

  render() {
    const {all_devices} = this.props
    //TO DO: add icons to life cycle
    let columns = [
      {
        Header: "Device Serial",
        accessor: "serial",
        filterMethod: (filter, row) => filterSubstring(filter, row),
      },
      {
        Header: "Name",
        accessor: "name",
        filterMethod: (filter, row) => filterSubstring(filter, row),
      }
    ]
    columns.push(...[{
      Header: "Device Type",
      accessor: "device_type",
      filterMethod: (filter, row) => filterSubstring(filter, row),
      Cell: (row) => {
        if (typeof row.value == "string") {

          let words = []
          row.value.split("_").forEach((word) => {
            words.push(word.charAt(0) + word.substring(1).toLowerCase())
          })

          return words.join(" ")
        }
        return row.value
      }
        },
      {
        Header: "Life Cycle",
        accessor: "life_cycle_stage",
        filterMethod: (filter, row) => filterSubstring(filter, row),
        Cell: row => (
          <div>{getIconByState(row.value)} {(() => {
            if (typeof row.value == "string") {

              let words = []
              row.value.split("_").forEach((word) => {
                words.push(word.charAt(0) + word.substring(1).toLowerCase())
              })

              return words.join(" ")
            }
            return  row.value
          })()}</div>
        )
      },
      {
        Header: "Metadata Valid?",
        accessor: "metadata_valid",
        filterable: false,
        // filterMethod: (filter, row) => filterSubstring(filter, row),
        Cell: (row) => {
          return row.value ? "Yes" : "No";
        }
      },
      {
        Header: "Actions",
        accessor: "serial",
        filterMethod: (filter, row) => filterSubstring(filter, row),
        Cell: (row) => {
          return <Link to={`/management/device_info?device_serial=${row.value}`}>To Details</Link>
        }
      },
    ])
    let tabs = [
      {
        tabButton: "Overview",
        tabContent: <MyReactTable
          id={"device_management"}
          data={all_devices}
          filterable
          columns={columns}
          getTrProps={(state, rowInfo, column) => {
            if (rowInfo && rowInfo.row) {
              if (rowInfo.row.life_cycle_stage === "DECOMMISSIONED") {
                return {
                  style: {
                    color: "rgba(255, 0, 0)"
                  }
                }
              } else if (rowInfo.row.life_cycle_stage === "OPERATIONAL") {
                return {
                  style: {
                    // color: "rgba(0, 255, 0)"
                  }
                }
              }
              return {}

            } else {
              return {}
            }
          }}
          defaultPageSize={10}
          showPaginationTop={false}
          showPaginationBottom={true}
          className=" -highlight"
        />,
      },
    ]
    tabs.push(
      {
        tabButton: "Actions",
        tabContent: <DeviceActions/>,
      }
    )
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader><h4 style={{color: primaryColor}}><b>Device Management</b></h4></CardHeader>
            <CardBody>
              <NavPills
                tabs={tabs}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

function mapStateToProps(state) {
  const {all_devices} = state.device_fleet
  return {
    all_devices
  }
}

function getIconByState(state) {
  switch (state) {
    case "DEPLOYMENT":
      return <CloudUploadTwoTone fontSize="small" style={{color: "grey"}}/>
    case "OPERATIONAL":
      return <LibraryAddCheckTwoTone fontSize="small" style={{color: "grey"}}/>
    case "TESTING":
      return <BugReportTwoTone fontSize="small" style={{color: "grey"}}/>
    case "SERVICE":
      return <SettingsTwoTone fontSize="small" style={{color: "grey"}}/>
    case "PRODUCTION":
      return <FitnessCenterTwoTone fontSize="small" style={{color: "grey"}}/>
    case "PRE_DEPLOYMENT":
      return <PauseCircleFilledTwoTone fontSize="small" style={{color: "grey"}}/>
    case "DECOMMISSIONED":
      return <CancelPresentationTwoTone fontSize="small" style={{color: "grey"}}/>
    case "COMMISSIONED":
      return <PresentToAllTwoTone fontSize="small" style={{color: "grey"}}/>
    default:
      return <PresentToAllTwoTone fontSize="small" style={{color: "grey"}}/>
  }
}

export default withStyles(userProfileStyles)(connect(mapStateToProps)(DeviceManagement))
