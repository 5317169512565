import React from "react"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"
import Card from "components/Card"
import CardBody from "components/CardBody"
import CardHeader from "components/CardHeader"

// Assets
import userProfileStyles from "./userProfileStyles.jsx"

// Services
import {deviceService} from '_services'
import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import {filterSubstring} from "../helper_functions/table_filtering";
import Button from "../../components/CustomButtons";
import {alertActions, sweetAlertActions} from "../../_actions";
import {Alert} from "@mui/material";
import MyReactTable from "../../components/MyReactTable/MyReactTable";
import {connect} from "react-redux";
import DeviceSelector from "./DeviceSelector";


class DeviceLogs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      device_logs: [],
    }
    this._is_mounted = false
  }

  componentDidMount() {
    this._is_mounted = true
    const {device_serial} = this.props
    this.refresh(device_serial)
  }

  componentWillUnmount() {
    this._is_mounted = false
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.device_serial !== this.props.device_serial) {
      this.refresh(this.props.device_serial)
    }
  }

  confirm_upload_all_incident_data() {
    const {dispatch} = this.props
    dispatch(sweetAlertActions.clean({
      title: "Are you sure?",
      showCancel: true,
      onCancel: this.clear_popup.bind(this),
      cancelBtnText: "No",
      onConfirm: this.upload_all_incident_data.bind(this),
      confirmBtnText: "Yes",
      content: 'This upload will only work when the device is connected to WiFi. To force update through 4G, upload the logs individually'
    }))

  }

  upload_all_incident_data() {
    const {device_serial, dispatch} = this.props
    deviceService.uploadAllIncidentData(device_serial, res => {
      dispatch(sweetAlertActions.clear())
      dispatch(alertActions.success("The command is sent to the device"))
    })

  }

  refresh(device_serial) {
    deviceService.getDeviceLogs(device_serial, res => {
        this.setState({device_logs: res.result})
      },
      reject => {
      })
  }

  upload_incident_data(database_id) {
    const {device_serial, dispatch} = this.props
    deviceService.uploadIncidentData(device_serial, database_id, res => {
      dispatch(alertActions.success("The command is sent to the device"))
      setTimeout(this.refresh.bind(this), 1000)
    })
  }

  clear_popup() {
    const {dispatch} = this.props;
    dispatch(sweetAlertActions.clear())
  }

  download_log_names() {
    const {device_logs} = this.state
    const {device_serial} = this.props

    const element = document.createElement("a");
    let textToWrite = device_logs.map((value) => {
      return value.database_id
    }).join(",")

    const file = new Blob([textToWrite.replaceAll(",", "\r\n")], {type: 'text/plain;charset=utf-8'})
    element.href = URL.createObjectURL(file);
    element.download = "device_logs_" + device_serial + ".txt";
    document.body.appendChild(element);
    element.click();
  }

  render() {
    const {device_serial, is_live, in_control, device_name, current_state} = this.props

    const {device_logs} = this.state
    let has_executor_access = true

    // FIXME: add executor access based on the authorisation

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <DeviceSelector disable_key_bindings={true}/>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader><h4 style={{color: primaryColor}}><b>Device Logs - {device_name}</b></h4>
            </CardHeader>
            <CardBody>
              <Alert severity={"info"}>
                Mind the device state, it is: <b>{current_state}</b>
              </Alert>
              <Button color="primary" onClick={this.refresh.bind(this, device_serial)}
                      disabled={!is_live}> Refresh Data </Button>
              <Button color="primary" onClick={this.download_log_names.bind(this)}
                      disabled={!is_live || (device_logs.length === 0)}> Download Log Names </Button>
              <Button color={'primary'} onClick={this.confirm_upload_all_incident_data.bind(this)}
                      disabled={!is_live || (device_logs.length === 0)}> Upload All</Button>
              {is_live ?
                device_logs.length === 0 ? (
                    <Alert severity={"info"}>No logs present on this device </Alert>
                  )
                  :
                  <MyReactTable
                    id={"device_logs"}
                    data={[
                      ...device_logs.map((log) => {
                        return {
                          "timestamp": log["timestamp"],
                          "database_id": log["database_id"],
                          "actions":
                            is_live ?
                              in_control ?
                                has_executor_access ?
                                  <Button color={'primary'}
                                          onClick={this.upload_incident_data.bind(this, log["database_id"])}>Upload</Button>
                                  :
                                  <div>You are not allowed.</div>
                                :
                                <div>You are not in control, request it.</div>
                              :
                              <div>Device is not online, upload unavailable.</div>
                        }
                      })
                    ]}
                    filterable
                    columns={[
                      {
                        Header: "Timestamp",
                        accessor: "timestamp",
                        Cell: row => {
                          return new Date(row.value).toLocaleString("nl-BE")
                        },
                        filterMethod: (filter, row) => filterSubstring(filter, row),
                      },
                      {
                        Header: "Database ID",
                        accessor: "database_id",
                        filterMethod: (filter, row) => filterSubstring(filter, row),
                      },
                      {
                        Header: "Upload Data",
                        accessor: "actions",
                        filterMethod: (filter, row) => filterSubstring(filter, row),
                      }
                    ]}
                    getTrProps={(state, rowInfo, column) => {
                      if (rowInfo && rowInfo.row) {
                        return {
                          style: {
                            background: rowInfo.row.level === "fatal" ? "rgba(255, 0, 0, 0.1)" : null
                          }
                        }
                      } else {
                        return {}
                      }
                    }}
                    defaultPageSize={10}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                    className=" -highlight"
                  />
                :
                <Alert severity={"warning"}>Logs are unavailable, the device is not online </Alert>
              }
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

function mapStateToProps(state) {
  const {device_serial} = state.device_fleet
  let is_live = false
  let in_control = false
  let current_state = "unknown"
  if (state.device_fleet && state.device_fleet.device_detail && state.device_fleet.device_detail.state && state.device_fleet.device_detail.state.state) {
    is_live = state.device_fleet.device_detail.state.is_live
    current_state = state.device_fleet.device_detail.state.state.value
  }

  if (state.device_fleet && state.device_fleet.device_detail && state.device_fleet.device_detail.in_control) {
    in_control = state.device_fleet.device_detail.in_control.in_control
  }

  let device_name = ""
  if (state.device_fleet && state.device_fleet.device_detail && state.device_fleet.device_detail.state) {
    device_name = state.device_fleet.device_detail.state.name
  }
  return {
    device_serial, is_live, in_control, current_state, device_name
  }
}

export default withStyles(userProfileStyles)(connect(mapStateToProps)(DeviceLogs))
