import React from "react"
import {connect} from 'react-redux'
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"
import Button from "components/CustomButtons"
import Card from "components/Card"
import CardBody from "components/CardBody"
import CardHeader from "components/CardHeader"

import extendedTablesStyle from "./extendedTablesStyle.jsx"

import {managementService, treatmentsService} from "_services"

import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";

import CustomInput from "../../components/CustomInput";
import GreenhouseMap from "../Maps/GreenhouseMap";
import {Brightness1TwoTone, Close} from "@material-ui/icons";
import Loader from "../../components/Loader";
import queryString from "query-string";
import Table from "../../components/Table";
import {Link} from "react-router-dom";
import {Alert} from "@mui/material";


class TreatmentDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      treatment_detail: {
        "settings": {},
        show_job_states: true,
        show_coverage: true,
        all_life_cycle_stages: {}
      },
      treatment_id: null
    }

    this.timer = null
    this._is_mounted = false
    this._refresh_rate = 3000
  }

  download_treatment(treatment_id) {
    treatmentsService.download(treatment_id)
  }

  change_value(config_name, value) {
    this.setState({[config_name]: value})
  }

  componentDidMount() {
    this._is_mounted = true

    const {treatment_id} = queryString.parse(this.props.location.search)
    let my_treatment_id = null

    if (treatment_id !== undefined && treatment_id !== null) {
      my_treatment_id = treatment_id
    }

    this.setState({treatment_id: my_treatment_id}, () => this.refresh())
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this._is_mounted = false
  }

  refresh() {
    const {treatment_id} = this.state
    managementService.getAllLifeCycleStages(res => {
      let all_life_cycle_stages = {}

      res.result.forEach((life_cycle_stage) => {
        all_life_cycle_stages[life_cycle_stage.id] = life_cycle_stage.name
      })

      this.setState({
        all_life_cycle_stages,
      })
    })
    if (treatment_id !== null) {
      treatmentsService.getTreatmentDetail(treatment_id, res => {
        this.setState({treatment_detail: res.result})
      })
    }
  }

  get_node_popup(feature) {
    const {jobs} = this.state.treatment_detail
    if (feature.properties.node_type === 'walkway') {
      return feature.id
    } else if (feature.properties.node_type === 'lane') {
      let end_state = "undefined"
      let end_error_code = ""

      if (jobs !== undefined) {
        let correct_jobs = jobs.filter((job) => job["object_id"] === feature.id)
        if (correct_jobs.length > 0) {
          correct_jobs.forEach((job) => {
            let job_state = job["status"].toLowerCase()
            if (end_state === "undefined") {
              end_state = job_state
            } else if (end_state === "failed" && job_state === "completed") {
              end_state = "recovered"
            }

            if (job_state === "failed") {
              end_error_code = job["error_code"]
            }
          })
        }
      }

      if (end_state === "queued") {
        return `${feature.id} - Queued`
      }

      if (end_state === "failed") {
        return `${feature.id} - Failed (${end_error_code})`
      }

      if (end_state === "completed") {
        return `${feature.id} - Completed`
      }
    }
    return feature.id
  }

  get_lane_style(feature) {
    const {show_job_states} = this.state
    const {jobs} = this.state.treatment_detail
    let color = 'white';
    let fillOpacity = 0.3;
    if (feature.properties.node_type === 'walkway') {
      color = '#0A485B';
    } else if (feature.properties.node_type === 'lane') {
      color = '#c0c0c0';
      let end_state = "undefined"

      if (jobs !== undefined) {
        let correct_jobs = jobs.filter((job) => job["object_id"] === feature.id)
        if (correct_jobs.length > 0) {
          correct_jobs.forEach((job) => {
            let job_state = job["status"].toLowerCase()
            if (end_state === "undefined") {
              end_state = job_state
            } else if (end_state === "failed" && job_state === "completed") {
              end_state = "recovered"
            }
          })
        }
      }
      if (show_job_states) {
        if (end_state === "queued") {
          color = '#ffd42c';
        }

        if (end_state === "failed") {
          color = '#d01c1c';
        }

        if (end_state === "recovered") {
          color = '#a9a60e';
        }

        if (end_state === "completed") {
          color = '#638813';
        }
      }
    }
    return {
      fillColor: color, fill: true, weight: 1, color: "#666", fillOpacity: fillOpacity
    };
  }

  clear_filters() {
    this.setState({show_job_states: false, show_coverage: false})
  }

  filter(name) {
    this.setState(prevState => ({
      [name]: !prevState[name]
    }));
  }

  render() {
    const {all_devices} = this.props
    const {treatment_detail, treatment_id, show_job_states, show_coverage, all_life_cycle_stages} = this.state
    if (!treatment_detail["settings"]) {
      treatment_detail["settings"] = {}
    }

    let finish_status = (treatment_detail["finish_status"] !== undefined) ? treatment_detail["finish_status"] !== "" ? treatment_detail["finish_status"].toLowerCase() : "UNKNOWN" : "UNKNOWN"
    let color = "gray"
    let failed_color = "rgba(204,5,5)"
    let stopped_color = "rgba(204,105,5)"
    let completed_color = "rgba(102,131,25)"
    let gutter_version
    if (treatment_detail["settings"] && treatment_detail["settings"]["site_version"]) {
      let [base, obs, miss, gutt, uv, sen, to, con, ve] = treatment_detail["settings"]["site_version"].split("#")
      gutter_version = gutt
    } else {
      gutter_version = undefined
    }

    if (finish_status === "failed") {
      color = failed_color
    }
    if (finish_status === "stopped") {
      color = stopped_color
    }
    if (finish_status === "paused") {
      color = stopped_color
    }
    if (finish_status === "fully_completed") {
      color = completed_color
    }
    if (finish_status === "partially_completed") {
      color = stopped_color
    }

    let device_name = "Unknown"
    all_devices.forEach((device) => {
      if (device["serial"] === treatment_detail["machine_serial"])
        device_name = device["name"]
    })

    let coverage_intervals = {}
    let coverage_interval_width = 0.25
    if (show_coverage) {
      if (treatment_detail["type"] === "xenion") {
        coverage_intervals = treatment_detail["lane_coverage"]
        coverage_interval_width = 0.25
      } else if (treatment_detail["type"] === "lumion") {
        coverage_intervals = treatment_detail["gutter_coverage"]
        coverage_interval_width = 0.25
      }
    }

    let converted = false
    if (treatment_detail["original_version"] && treatment_detail["version"]) {
      converted = treatment_detail["original_version"] !== treatment_detail["version"]
    }
    return (
      <Card>
        <CardHeader>
          <GridContainer>
            <GridItem xs={4}>
              <h4 style={{color: primaryColor}}>
                <b>{"Treatment Detail"}</b>
              </h4>
            </GridItem>
            <GridItem xs={8} style={{textAlign: "right"}}>
              <CustomInput
                labelText={"Treatment ID"}
                inputProps={{
                  onChange: event => this.change_value("treatment_id", event.target.value),
                  type: "text",
                  value: treatment_id
                }}
              />
              &nbsp;&nbsp;
              <Button color={"primary"} onClick={() => this.refresh()}>Get Detail</Button>
              <Button color="primary" onClick={this.refresh.bind(this)}> Refresh Data </Button>
              <Button color="primary"
                      onClick={this.download_treatment.bind(this, treatment_id)}> Download </Button>
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12}>
              <GridContainer>
                <GridItem xs={12} md={12}>
                  {converted && <Alert severity={"warning"}> Treatment has been converted from
                    v{treatment_detail["original_version"]} to
                    v{treatment_detail["version"]}</Alert>}

                  <br/>
                </GridItem>
                <GridItem xs={12} md={6}>
                  <Table
                    color={"primary"}
                    tableData={[
                      [<b>ID</b>, treatment_detail["id"]],
                      [<b>Finish Status</b>,
                        <span>
                                                    <Brightness1TwoTone style={{color: color, maxHeight: "10px"}}/>
                          {finish_status}
                          &nbsp;-&nbsp;
                          {treatment_detail["stats"] && Math.round(treatment_detail["stats"]["coverage"] * 100, 2) + " %"}
                                                </span>],
                      [
                        <b>Created</b>, new Date(treatment_detail["creation_time"]).toLocaleString("nl-BE")],
                      [<b>Started</b>,
                        treatment_detail["start_time"] === null ?
                          <i>{new Date(new Date(treatment_detail["creation_time"]).getTime() + (treatment_detail["wait_time"] * 1000)).toLocaleString("nl-BE")} (estimated)</i>
                          :
                          new Date(treatment_detail["start_time"]).toLocaleString("nl-BE")
                      ],
                      [<b>Finished</b>, treatment_detail["finish_status"] === "" ?
                        <Loader/> : new Date(treatment_detail["finish_time"]).toLocaleString("nl-BE")],
                      ["", ""]
                    ]}/>
                </GridItem>
                <GridItem xs={12} md={6}>
                  <Table
                    color={"primary"}
                    tableData={[
                      [<b>Device</b>, <Link
                        to={`/device/treatments?device_serial=${treatment_detail["machine_serial"]}`}>To
                        Overview for {device_name} </Link>],
                      [<b>Life Cycle
                        Stage</b>, all_life_cycle_stages && all_life_cycle_stages[treatment_detail["device_life_cycle_stage"]] ? all_life_cycle_stages[treatment_detail["device_life_cycle_stage"]] : treatment_detail["device_life_cycle_stage"]],
                      [<b>Site
                        ID</b>, treatment_detail["settings"]["site_id"] + " - " + treatment_detail["map_name"]],
                      [<b>Mission Name (Mission
                        Type)</b>, `${treatment_detail["settings"]["mission_id"]} (${treatment_detail["type"]})`],
                      [<b>Velocity</b>, treatment_detail["settings"]["velocity"] !== undefined ?
                        <span>{(Math.round((treatment_detail["settings"]["velocity"] * 3.6 + Number.EPSILON) * 100) / 100)} km/h ({Math.round((treatment_detail["settings"]["velocity"] + Number.EPSILON) * 100) / 100} m/s)</span>
                        : "Unknown"],
                      ["", ""],
                      [<b>Incidents</b>,
                        treatment_detail["id"] !== undefined ?
                        <Link to={`/incidents/all?treatment_id=${treatment_detail["id"]}`}>To Incidents</Link>
                      :
                      ""],
                      ["", ""]
                    ]}/>
                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem xs={12}>
              <Button simple color={"primary"} onClick={this.filter.bind(this, "show_job_states")}>
                {show_job_states ? <b>Show Job States</b> : <span>Show Job States</span>}
              </Button>
              <Button simple color={"primary"} onClick={this.filter.bind(this, "show_coverage")}>
                {show_coverage ? <b>Show Coverage</b> : <span>Show Coverage</span>}
              </Button>
              <Button simple
                      color={show_job_states === false && show_coverage === false ? "gray" : "danger"}
                      onClick={this.clear_filters.bind(this)}
                      disabled={show_job_states === false && show_coverage === false}>
                <Close/> Clear View
              </Button>
              <GreenhouseMap
                map_id={treatment_detail["settings"]["site_id"]}
                map_version={treatment_detail["settings"]["site_version"]}
                gutter_layer_version={gutter_version}
                style_function={this.get_lane_style.bind(this)}
                popup_function={this.get_node_popup.bind(this)}
                coverage_overlay={coverage_intervals}
                coverage_overlay_width={coverage_interval_width}
                force_refresh={show_job_states}
                alternate={
                  <GridContainer>
                    {treatment_detail["jobs"] !== undefined && treatment_detail["jobs"].filter(job => treatment_detail["in_progress"] ? job.status === "FAILED" : job.status !== "COMPLETED").length > 0 &&
                      <GridItem xs={12}>
                        <b>Failed Jobs</b>
                        <br/>
                        {
                          treatment_detail["jobs"].filter(job => treatment_detail["in_progress"] ? job.status === "FAILED" : job.status !== "COMPLETED").map((failed_job, idx) => {
                            return <p key={idx}><Brightness1TwoTone style={{
                              color: "rgba(204,5,5)",
                              maxHeight: "10px"
                            }}/> {failed_job["gutter"]} ({failed_job["error_code"]})</p>
                          })
                        }
                        <br/>
                      </GridItem>
                    }
                    {treatment_detail["jobs"] !== undefined && treatment_detail["jobs"].filter(job => job.status !== "COMPLETED").length <= 0 &&
                      <GridItem xs={12}>
                        <Alert color={'success'}>All Completed</Alert>
                      </GridItem>
                    }
                  </GridContainer>
                }
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    )
  }
}


function mapStateToProps(state) {
  const {error_codes, device_serial, all_devices} = state.device_fleet

  let is_live = false
  if (state.device_fleet && state.device_fleet.device_detail && state.device_fleet.device_detail.state) {
    is_live = state.device_fleet.device_detail.state.is_live
  }

  let device_name = ""
  if (state.device_fleet && state.device_fleet.device_detail && state.device_fleet.device_detail.state) {
    device_name = state.device_fleet.device_detail.state.name
  }

  return {
    device_serial, is_live, device_name, error_codes, all_devices
  }
}

export default withStyles(extendedTablesStyle)(connect(mapStateToProps)(TreatmentDetail))