import React, {useEffect, useState} from "react"
import {connect} from 'react-redux'
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"

import extendedTablesStyle from "./extendedTablesStyle.jsx"

import {incidentService} from "_services"

import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import Table from "../../components/Table";

import {api_root} from "../../index";
import {Alert} from "@mui/material";
import Button from "../../components/CustomButtons";
import {Link} from "react-router-dom";
import {store} from "_helpers/store";


const VideoComponent = ({incident_id}) => {
  const [videoUrl, setVideoUrl] = useState(null);

  useEffect(() => {
    const fetchVideo = async () => {
      const state = store.getState()
      const response = await fetch(api_root + "/incidents/video/" + incident_id, {
        headers: {
          'Authorization': 'Bearer ' + state.authentication.user.accessToken.jwtToken
        }
      });
      const videoData = await response.blob();
      const blobUrl = URL.createObjectURL(videoData);
      setVideoUrl(blobUrl);
    };
    fetchVideo();
  }, []);

  return (
    <>
      {videoUrl ? (
        <video width={"50%"} controls>
          <source src={videoUrl} type="video/mp4"/>
        </video>
      ) : (
        <p>Loading video...</p>
      )}
    </>
  );
};


class IncidentDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      incident_detail: {},
      last_updated: null,
      loading: false,
      downloading: "",
    }

    this.timer = null
    this._is_mounted = false
    this._refresh_rate = 5000
  }

  componentDidMount() {
    this._is_mounted = true
    const {incident_id} = this.props
    this.refresh(incident_id)
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.incident_id !== nextProps.incident_id) {
      clearTimeout(this.timer)
      this.refresh(nextProps.incident_id)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this._is_mounted = false
  }

  refresh(incident_id) {
    incidentService.getIncidentRecord(incident_id, result => {
        this.setState({incident_detail: result.incident, loading: false, last_updated: new Date()})
        if (this._is_mounted) {
          this.timer = setTimeout(() => this.refresh(incident_id), this._refresh_rate)
        }
      },
      reject => {
        if (this._is_mounted) {
          this.timer = setTimeout(() => this.refresh(incident_id), this._refresh_rate * 2)
        }
      }
    )
  }

  download(incident_id, file_name, downloaded_file_name) {
    this.setState({downloading: file_name})
    incidentService.downloadFile(incident_id, file_name, downloaded_file_name, res => {
      this.setState({downloading: ""})
    })
  }

  get_post_processing_success(result, pp_type) {
    if (result === undefined || result === null)
      return {
        "success": false,
        "message": "Post Processing not started",
        "retry_possible": true,
      }

    if (result["post_processing_results"] === undefined || result["post_processing_results"] === null)
      return {
        "success": false,
        "message": "Post Processing not started",
        "retry_possible": true,
      }

    if (result["post_processing_results"][pp_type] === undefined || result["post_processing_results"][pp_type] === null)
      return {
        "success": false,
        "message": "Post Processing not started",
        "retry_possible": true,
      }

    return result["post_processing_results"][pp_type]
  }

  retry_post_processing(post_processing_type) {
    const {incident_id} = this.props

    incidentService.retryPostProcessing(incident_id, post_processing_type, res => {
      this.refresh(incident_id)
    })
  }

  render() {
    const {incident_id, all_devices, error_codes} = this.props
    const {
      incident_detail,
      downloading,
    } = this.state

    let device_name = "Unknown"
    all_devices.forEach((device) => {
      if (device["serial"] === incident_detail["machine_serial"])
        device_name = device["name"]
    })

    return (
      Object.keys(incident_detail).length === 0 ?
        (
          <GridContainer>
            <GridItem xs={12} md={6}>
              <GridContainer>
                <GridItem xs={12}>
                  <h5 style={{color: primaryColor}}>Incident Information</h5>
                  <Alert variant={"outlined"} severity={"error"}>Incident Not Found</Alert>
                </GridItem>
                <GridItem xs={12}>
                  <h5 style={{color: primaryColor}}>Data Store Information</h5>
                  <Alert variant={"outlined"} severity={"error"}>Incident Not Found</Alert>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} md={6}>
              <h5 style={{color: primaryColor}}>Video</h5>
              <Alert variant={"outlined"} severity={"error"}>Incident Not Found</Alert>
            </GridItem>
          </GridContainer>
        )
        :
        (
          <GridContainer>
            <GridItem xs={12} md={6}>
              <GridContainer>
                <GridItem xs={12}>
                  <h5 style={{color: primaryColor}}>Incident Information</h5>
                  {
                    incident_detail["manually_triggered"] &&
                    <Alert variant={"outlined"} severity={"warning"}>This incident is manually
                      triggered</Alert>
                  }
                  <Table
                    color={"primary"}
                    tableData={[
                      [<b>ID</b>, incident_detail["incident_id"]],
                      [
                        <b>Time</b>, new Date(incident_detail["incident_time"]).toLocaleString("nl-BE")],
                      [<b>Error Code</b>, incident_detail["error_code"]],
                      ["", error_codes[incident_detail["error_code"]] ? error_codes[incident_detail["error_code"]]["development"] : "Unknown Error Code"],
                      [<b>Device Name</b>, device_name],
                      [<b>Upload
                        Started</b>, incident_detail["upload_results"] ? new Date(incident_detail["upload_results"]["upload_started"]).toLocaleString("nl-BE") : "-"],
                      [<b>Upload
                        Finished</b>, incident_detail["upload_results"] ? new Date(incident_detail["upload_results"]["upload_finished"]).toLocaleString("nl-BE") : "-"],
                      ["", ""]
                    ]}/>
                </GridItem>

                <GridItem xs={12}>
                  <h5 style={{color: primaryColor}}>Meta Data</h5>
                  <Table
                    color={"primary"}
                    tableData={[
                      [<b>Map ID</b>, incident_detail["map_id"]],
                      [<b>Map Version</b>, incident_detail["map_version"]],
                      [<b>During Mission</b>, incident_detail["during_mission"] ? "YES" : "NO"],
                      [<b>Treatment ID</b>,
                        <Link
                          to={`/treatments/detail?treatment_id=${incident_detail["treatment_id"]}`}>
                          {incident_detail["treatment_id"]}
                        </Link>
                      ],
                      // [<b>Mission Name</b>, incident_detail["mission_name"]],
                      [<b>Node ID</b>, incident_detail["node_id"]],
                      ["", ""]
                    ]}/>
                </GridItem>

                <GridItem xs={12}>
                  <h5 style={{color: primaryColor}}>Extra Information</h5>
                  <Table
                    color={"primary"}
                    tableData={[
                      [<b>Map Overview</b>,
                        <Link to={`/incidents/per_map?map_id=${incident_detail["map_id"]}`}> To
                          map {incident_detail["map_id"]}</Link>],
                      [<b>Incident Dashboard</b>, <a target={"new"}
                                                     href={incident_detail["incident_dashboard_url"]}> Incident
                        Dashboard </a>],
                      [<b>Text Logging</b>,
                        <a target={"new"} href={incident_detail["text_logging_url"]}> Kibana
                          Dashboard </a>],
                      [<b></b>,
                        <a target={"new"} href={incident_detail["cleaned_text_logging_url"]}> Kibana
                          Dashboard (cleaned) </a>],
                      [<b>Rosbag (zipped)</b>, downloading === "rosbag" ? <p>Downloading...</p> :
                        <a href={"#"}
                           onClick={this.download.bind(this, incident_id, "rosbag", incident_id + ".bag.gz")}> Download </a>],
                      [<b>Depth Video</b>, downloading === "depth" ? <p>Downloading...</p> :
                        <a href={"#"}
                           onClick={this.download.bind(this, incident_id, "depth", incident_id + ".dat")}> Download </a>],
                      [<b>Ros Parameters</b>, downloading === "rosparam" ? <p>Downloading...</p> :
                        <a href={"#"}
                           onClick={this.download.bind(this, incident_id, "rosparam", incident_id + ".json")}> Download </a>],
                      ["", ""]
                    ]}/>
                </GridItem>
                <GridItem xs={12}>
                  <h5 style={{color: primaryColor}}>Data Store Information</h5>
                  <Table
                    color={"primary"}
                    tableData={[
                      [<b>Data Store ID</b>, incident_detail["data_store_id"]],
                      [<b>Files ID</b>, incident_detail["files_id"]],
                      [<b>Record Version</b>, incident_detail["record_version"]],
                      ["", ""]
                    ]}/>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} md={6}>
              <GridContainer>
                <GridItem xs={12}>
                  <h5 style={{color: primaryColor}}>Post Processing</h5>
                  {incident_detail["post_processing_result"] !== undefined ?
                    <Alert variant={"outlined"} severity={"error"}>No result found</Alert>
                    :
                    <Table
                      color={"primary"}
                      tableData={[
                        [
                          <b>Data Parsed</b>,
                          this.get_post_processing_success(incident_detail, "data_parsing")["success"] ? "Yes" : "No",
                          this.get_post_processing_success(incident_detail, "data_parsing")["message"],
                          this.get_post_processing_success(incident_detail, "data_parsing")["retry_possible"] ?
                            <Button simple round color="primary"
                                    onClick={this.retry_post_processing.bind(this, "data_parsing")}>Retry</Button> : "-"

                        ],
                        [
                          <b>Logs Parsed</b>,
                          this.get_post_processing_success(incident_detail, "log_parsing")["success"] ? "Yes" : "No",
                          this.get_post_processing_success(incident_detail, "log_parsing")["message"],
                          this.get_post_processing_success(incident_detail, "log_parsing")["retry_possible"] ?
                            <Button simple round color="primary"
                                    onClick={this.retry_post_processing.bind(this, "log_parsing")}>Retry</Button> : "-"
                        ],
                        [
                          <b>Mosaic Rendered</b>,
                          this.get_post_processing_success(incident_detail, "mosaic_rendering")["success"] ? "Yes" : "No",
                          this.get_post_processing_success(incident_detail, "mosaic_rendering")["message"],
                          this.get_post_processing_success(incident_detail, "mosaic_rendering")["retry_possible"] ?
                            <Button simple round color="primary"
                                    onClick={this.retry_post_processing.bind(this, "mosaic_rendering")}>Retry</Button> : "-"
                        ],
                        ["", "", "", ""]
                      ]}/>
                  }

                </GridItem>
                <GridItem xs={12}>
                  <h5 style={{color: primaryColor}}>Video</h5>
                  {incident_id && this.get_post_processing_success(incident_detail, "mosaic_rendering")["success"] && this.get_post_processing_success(incident_detail, "mosaic_rendering")["message"] === "Rendering successful" ?
                    (
                      <VideoComponent incident_id={incident_id}/>
                    ) : (
                      <Alert variant={"outlined"} severity={"error"}>Mosaic was not
                        rendered</Alert>
                    )
                  }
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        )
    )
  }
}


function mapStateToProps(state) {
  const {all_devices} = state.device_fleet
  const {error_codes} = state.device_fleet
  return {
    all_devices, error_codes
  }
}


export default withStyles(extendedTablesStyle)(connect(mapStateToProps)(IncidentDetail))
