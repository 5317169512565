import React, {useRef} from "react"
import {connect} from 'react-redux'
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"
import Card from "components/Card"
import CardBody from "components/CardBody"
import CardHeader from "components/CardHeader"

import extendedTablesStyle from "./extendedTablesStyle.jsx"

import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import {mapService} from "../../_services";
import {filterSubstring} from "../helper_functions/table_filtering";
import MyReactTable from "../../components/MyReactTable/MyReactTable";
import Button from "../../components/CustomButtons";
import {CloseTwoTone} from "@material-ui/icons";

import {sweetAlertActions} from "../../_actions";
import CustomInput from "../../components/CustomInput";
import {Alert} from "@mui/material";
import {Link} from "react-router-dom";
import NavPills from "../../components/NavPills";

const FileUploader = ({handleFile}) => {
  // Create a reference to the hidden file input element
  const hiddenFileInput = useRef(null);

  // Programmatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    handleFile(fileUploaded);
  };
  return (
    <>
      <Button simple color={"primary"} onClick={handleClick}>
        Upload a map (.zip)
      </Button>
      <input
        type="file"
        id="ctrl"
        onChange={handleChange}
        ref={hiddenFileInput}
        style={{display: "none"}} // Make the file input element invisible
      />
    </>
  );
};


class Maps extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      totalResults: 0,
      totalPages: 0,
      pageSize: 10,
      currentPage: 1,
      loading_maps: false,
      data: [],

      totalResultsConti: 0,
      totalPagesConti: 0,
      pageSizeConti: 10,
      currentPageConti: 1,
      loading_maps_conti: false,
      dataConti: [],
      map_ids: [],
      upload_map: false,
      map_linked_to_greenhouse: {},
      updated_properties: {}
    }

    this.timer = null
    this._is_mounted = false
    this._refresh_rate = 3000
  }

  componentDidMount() {
    this._is_mounted = true
    this.refresh()
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this._is_mounted = false
  }

  refresh() {
    const {currentPage, currentPageConti, pageSize, pageSizeConti} = this.state

    this.setState({loading_maps: true, loading_maps_conti: true})
    mapService.getMapIds(result => {
      this.setState({map_ids: result.results})
    })
    mapService.getAllMaps(pageSize, currentPage - 1, result => {
        const total_pages = Math.ceil(result.total / pageSize)
        let newCurrentPage = currentPage
        if (currentPage > total_pages && result.total > 0) {
          newCurrentPage = total_pages
        }
        this.setState({
          totalResults: result.total,
          currentPage: newCurrentPage,
          data: result.results,
          totalPages: total_pages,
          loading_maps: false,
        })
        result.results.forEach((map) => {
          mapService.getGreenhouseByMapID(map["id"], res => {
            let {map_linked_to_greenhouse} = this.state
            map_linked_to_greenhouse[map["id"]] = res.greenhouse ? res.greenhouse.name : null;
            this.setState({map_linked_to_greenhouse})
          })
        })
      },
      reject => {
        this.setState({loading_maps: false})
      })

    mapService.getContinentalMaps(pageSizeConti, currentPageConti - 1, result => {
        const total_pages = Math.ceil(result.total / pageSizeConti)
        let newCurrentPage = currentPageConti
        if (currentPageConti > total_pages && result.total > 0) {
          newCurrentPage = total_pages
        }
        this.setState({
          totalResultsConti: result.total,
          currentPageConti: newCurrentPage,
          dataConti: result.results,
          totalPagesConti: total_pages,
          loading_maps_conti: false,
        })
      },
      reject => {
        this.setState({loading_maps_conti: false})
      })
  }

  handle_form_input_selector(name, value) {
    this.setState({[name]: value})
  }

  clear_popup() {
    const {dispatch} = this.props;
    dispatch(sweetAlertActions.clear())
  }

  change_value(value_name, value) {
    let {updated_properties} = this.state
    updated_properties[value_name] = value
    this.setState({updated_properties})
  }

  handle_folder(zipped_map) {
    this.change_value("file", zipped_map)
  }

  upload_map() {
    const {dispatch} = this.props
    const {updated_properties} = this.state
    mapService.uploadNewMap(updated_properties["map_id"], updated_properties["map_version"], updated_properties["file"], res => {
      this.setState({upload_map: false}, () => this.refresh())
      dispatch(sweetAlertActions.success({title: "Map is uploaded"}))
    })
  }

  render() {
    const {
      currentPage,
      currentPageConti,
      data,
      dataConti,
      pageSize,
      pageSizeConti,
      totalResults,
      totalResultsConti,
      totalPages,
      totalPagesConti,
      loading_maps,
      loading_maps_conti,
      map_linked_to_greenhouse,
      upload_map,
      updated_properties,
      map_ids
    } = this.state

    let columns = [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Versions",
        accessor: "versions",
        Cell: row => {
          return row.value.join(", ")
        }
      },
      {
        Header: "Map Name",
        accessor: "name",
      },
      {
        Header: "Greenhouse Name",
        accessor: "id",
        filterable: false,
        sortable: false,
        Cell: row => {
          if (map_linked_to_greenhouse[row.value] === undefined)
            return "-"

          if (map_linked_to_greenhouse[row.value])
            return <span> {map_linked_to_greenhouse[row.value]} </span>

          return <span style={{color: "rgb(231,92,92)"}}> <CloseTwoTone/> </span>
        }
      },
      {
        Header: "",
        accessor: "id",
        Cell: row => {
          return <Link to={`/maps/map_detail?map_id=${row.value}`}>To Detail </Link>
        },
        sortable: false, filterable: false, width: 100
      },
    ]

    let columnsConti = [
      {
        Header: "ID",
        accessor: "id",
        filterMethod: (filter, row) => filterSubstring(filter, row),
      },
      {
        Header: "Version",
        accessor: "version",
        filterMethod: (filter, row) => filterSubstring(filter, row),
      },
      {
        Header: "Name",
        accessor: "name",
      }
    ]

    let tabs =
      [
        {
          tabButton: "Octiva",
          tabContent: <GridContainer>
            <GridItem xs={12} md={10}>
              <h5># Results: {totalResults}</h5>
            </GridItem>
            <GridItem xs={12} md={2}>
              <Button disabled simple color={"primary"} onClick={() => {
                this.setState({upload_map: true})
              }}>Upload a map [Coming Soon]</Button>
            </GridItem>
            <GridItem xs={12}>
              <MyReactTable
                id={"maps"}
                data={[
                  ...Array(currentPage === 1 ? 0 : (currentPage - 1) * pageSize).keys(),
                  ...data
                ]}
                columns={columns}
                sortable={false}
                filterable={false}
                loading={loading_maps}
                pages={totalPages}
                page={currentPage - 1}
                pageSize={pageSize}
                onPageChange={(pageIndex) => this.setState({currentPage: pageIndex + 1}, () => this.refresh())}
                onPageSizeChange={(newPageSize) => {
                  this.setState({currentPage: 1, pageSize: newPageSize}, () => this.refresh())
                }}
              />
            </GridItem>
          </GridContainer>
        }]

    if (window.CONTINENTAL_INTEGRATION_ENABLED) {
      tabs.push({
        tabButton: "Continental",
        tabContent: <GridContainer>
            <GridItem xs={12} md={10}>
              <h5># Results: {totalResultsConti}</h5>
            </GridItem>
            <GridItem xs={12} md={12}>
          <MyReactTable
            id={"maps_continental"}
            data={[
              ...Array(currentPageConti === 1 ? 0 : (currentPageConti - 1) * pageSizeConti).keys(),
              ...dataConti
            ]}
            columns={columnsConti}
            sortable={false}
            filterable={false}
            loading={loading_maps_conti}
            pages={totalPagesConti}
            page={currentPageConti - 1}
            pageSize={pageSizeConti}
            onPageChange={(pageIndex) => this.setState({currentPageConti: pageIndex + 1}, () => this.refresh())}
            onPageSizeChange={(newPageSize) => {
              this.setState({currentPageConti: 1, pageSizeConti: newPageSize}, () => this.refresh())
            }}
          />
            </GridItem>
        </GridContainer>
      })
    }

    return (
      <GridContainer>
        {upload_map &&
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader><h4 style={{color: primaryColor}}><b>{"Overview Maps"}</b></h4></CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} md={5}>
                    <p><b>Map ID:</b></p>
                    <CustomInput
                      style={{
                        fullWidth: true,
                        width: "100%"
                      }}
                      id={"map_id"}
                      labelText={"Map ID"}
                      inputProps={{
                        onChange: event => this.change_value("map_id", event.target.value),
                        type: "text",
                        value: updated_properties["map_id"]
                      }}
                    />

                  </GridItem>
                  <GridItem xs={12} md={5}>

                    <p><b>Zipped File:</b></p>
                    {
                      updated_properties["file"] ?
                        updated_properties["file"].name
                        :
                        <FileUploader handleFile={this.handle_folder.bind(this)}/>
                    }
                  </GridItem>
                  <GridItem xs={12} md={2}>
                    <Button simple color={"primary"} onClick={this.upload_map.bind(this)}>Upload</Button>
                    <Button simple color={"danger"} onClick={() => {
                      this.setState({upload_map: false, updated_properties: {}})
                    }}>Cancel</Button>

                  </GridItem>
                  <GridItem xs={12}>
                    {map_ids.includes(updated_properties["map_id"]) &&
                      <Alert severity={"warning"}>You will upload a new version of an existing map </Alert>
                    }
                  </GridItem>

                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>

        }
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader><h4 style={{color: primaryColor}}><b>{"Overview Maps"}</b></h4></CardHeader>
            <CardBody>
              <NavPills
                tabs={tabs}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}


function mapStateToProps(state) {
  return {
  }
}


export default withStyles(extendedTablesStyle)(connect(mapStateToProps)(Maps))
