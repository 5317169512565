import React from "react"
import {connect} from 'react-redux'
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"
import Card from "components/Card"
import CardBody from "components/CardBody"
import CardHeader from "components/CardHeader"

import extendedTablesStyle from "./extendedTablesStyle.jsx"

import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import {managementService} from "../../_services";
import MyDropdown from "../../components/MyDropdown";
import Table from "../../components/Table";
import Button from "../../components/CustomButtons";
import {Alert} from "@mui/material";
import _ from 'lodash';

class Authorisation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error_message: "Authorisation not available at the moment",
    }

    this.timer = null
    this._is_mounted = false
    this._refresh_rate = 3000
  }

  componentDidMount() {
    this._is_mounted = true
    this.refresh()
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this._is_mounted = false
  }

  refresh() {
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.organisations !== this.props.organisations || prevProps.locations !== this.props.locations || prevProps.all_devices !== this.props.all_devices || prevProps.deployments !== this.props.deployments) {
      this.refresh()
    }
  }

  render() {
    const {
      error_message,
    } = this.state

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader>
              <GridContainer>
                <GridItem xs={10}>
                  <h4 style={{color: primaryColor}}>
                    <b>{"Authorisation"}</b></h4>
                </GridItem>
                <GridItem xs={2}>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridContainer>
                  <GridItem xs={12}>
                    <Alert severity={"error"}>{error_message}</Alert>
                  </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}


function mapStateToProps(state) {
  const {organisations, locations, deployments} = state.location
  const {all_devices} = state.device_fleet
  return {
    organisations, locations, all_devices, deployments
  }
}


export default withStyles(extendedTablesStyle)(connect(mapStateToProps)(Authorisation))
