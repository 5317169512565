import React from "react"
import {connect} from 'react-redux'
import {filterSubstring} from "../helper_functions/table_filtering";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"
import Card from "components/Card"
import CardBody from "components/CardBody"
import CardHeader from "components/CardHeader"

import extendedTablesStyle from "./extendedTablesStyle.jsx"

import {managementService, systemService} from "_services"

import {sortObj} from "views/helper_functions/sorting";
import {Brightness1TwoTone, CloseTwoTone, DoneTwoTone, Wifi,} from "@material-ui/icons";
import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import MyReactTable from "../../components/MyReactTable/MyReactTable";

import ReactTimeAgo from 'react-time-ago'
import {get_connection_icon} from "../helper_functions/device_icons";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "../../components/CustomButtons";
import {Alert} from "@mui/material";


class SettingsFleet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      settings_overview: [],
      settings_detail: {},
      settings_page: 0,
      last_updated: null,
      loading: false,
      all_device_types: {},
      all_life_cycle_stages: {},
      show_device_settings: true,
      show_software_info: false,
      show_map_info: false,
      show_sim_info: false,
    }

    this.timer = null
    this._is_mounted = false
    this._refresh_rate = 3000
  }

  on_page_change(new_page) {
    this.setState({settings_page: new_page})
  }

  componentDidMount() {
    this._is_mounted = true
    this.setState({loading: true})
    this.refresh()
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this._is_mounted = false
  }

  refresh() {
    const {all_devices} = this.props
    managementService.getAllLifeCycleStages(res => {
      let all_life_cycle_stages = {}

      res.result.forEach((life_cycle_stage) => {
        all_life_cycle_stages[life_cycle_stage.id] = life_cycle_stage.name
      })

      managementService.getAllDeviceTypes(res => {
        let all_device_types = {}

        res.result.forEach((device_type) => {
          all_device_types[device_type.id] = device_type.name
        })

        systemService.getStateOverview(
          result => {
            let devices = sortObj(result.result, 'id')
            let test2 = devices.map((device, key) => {

              let life_cycle_stage_name = "Unknown"
              let device_type_name = "Unknown"
              if (all_devices) {
                all_devices.forEach((d) => {
                  if (d["serial"] === device["device_serial"]) {
                    life_cycle_stage_name = all_life_cycle_stages[d["life_cycle_stage"]]
                    device_type_name = all_device_types[d["device_type"]]
                  }
                })
              }

              return {
                id: key,
                ...device,
                life_cycle_stage_name: life_cycle_stage_name,
                device_type_name: device_type_name,

              }
            })
            this.setState({
              all_life_cycle_stages,
              all_device_types,
              settings_overview: test2,
              loading: false,
              last_updated: new Date(result.updated)
            })
            if (this._is_mounted) {
              this.timer = setTimeout(() => this.refresh(), this._refresh_rate)
            }
          },
          reject => {
            this.setState({settings_overview: [], loading: false})
            if (this._is_mounted) {
              this.timer = setTimeout(() => this.refresh(), this._refresh_rate * 2)
            }
          }
        )
      })
    })
  }

  render() {
    const {classes} = this.props
    const {
      settings_overview,
      loading,
      settings_page,
      last_updated,
      show_device_settings,
      show_software_info,
      show_map_info,
      show_sim_info
    } = this.state

    let columns = [
      {
        Header: <Wifi fontSize="small" style={{color: "rgb(173,170,170)"}}/>,
        accessor: "is_live.value",
        Cell: row => (
          get_connection_icon(classes, row.value, row.original.last_seen, row.original.has_wifi.value)
        ),
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }

          if (filter.value === "online") {
            return row["is_live.value"] === true;
          }

          if (filter.value === "offline") {
            return row["is_live.value"] === false;
          }

          return false;
        },

        Filter: ({filter, onChange}) =>
          <FormControl fullWidth className={classes.selectFormControl}>
            <Select
              onChange={event => onChange(event.target.value)}
              style={{width: "100%"}}
              value={filter ? filter.value : "all"}
            >
              <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                        value={'all'}>
                {"all"}
              </MenuItem>
              <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                        value={'online'}>
                <Brightness1TwoTone fontSize="small" style={{color: "rgb(127, 175, 12)"}}/>
              </MenuItem>
              <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                        value={'offline'}>
                <Brightness1TwoTone fontSize="small" style={{color: "rgb(175,12,12)"}}/>
              </MenuItem>
            </Select>
          </FormControl>,
        width: 50,
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: row => (
          <b>{row.value}</b>
        ),
        filterMethod: (filter, row) => filterSubstring(filter, row)
      },
      {
        Header: "Last Sent",
        accessor: "last_seen",
        Cell: (row) => {
          if (row.value === "" || row.value === null || row.value === undefined) {
            return "Unknown"
          }
          return <ReactTimeAgo date={row.value} locale="en-UK"/>
        },
        filterable: false
      }
    ]

    if (show_device_settings) {
      columns.push(
        ...[
          {
            Header: "Serial Number",
            accessor: "device_serial",
            filterMethod: (filter, row) => filterSubstring(filter, row)
          },
          {
            Header: "Device Type",
            accessor: "device_type_name",
            filterMethod: (filter, row) => filterSubstring(filter, row)
          },
          {
            Header: "Life Cycle Stage",
            accessor: "life_cycle_stage_name",
            filterMethod: (filter, row) => filterSubstring(filter, row)
          }]
      )
    }

    if (show_software_info) {
      columns.push(...[
        {
          Header: "Software Version",
          accessor: "software_version.value",
          filterMethod: (filter, row) => filterSubstring(filter, row)
        }]
      )
    }

    if (show_map_info) {
      columns.push(...[
        {
          Header: "Maps Valid?",
          accessor: "all_maps_valid.value",
          width: 120,
          sortable: false,
          Cell: row => {
            if (row.value !== true && row.value !== false) {
              return "-"
            }

            return <span style={{color: row.value ? "rgb(100,169,68)" : "rgb(231,92,92)"}}>
                        {
                          row.value ? <DoneTwoTone/> : <CloseTwoTone/>
                        }
                    </span>

          },
          filterMethod: (filter, row) => {
            if (filter.value === "all") {
              return true;
            }

            if (filter.value === "unknown") {
              return row["all_maps_valid.value"] !== true && row["all_maps_valid.value"] !== false;
            }

            if (filter.value === "valid") {
              return row["all_maps_valid.value"] === true;
            }

            if (filter.value === "invalid") {
              return row["all_maps_valid.value"] === false;
            }

            return false;
          },

          Filter: ({filter, onChange}) =>
            <FormControl fullWidth className={classes.selectFormControl}>
              <Select
                onChange={event => onChange(event.target.value)}
                style={{width: "100%"}}
                value={filter ? filter.value : "all"}
              >
                <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                          value={'all'}>
                  {"all"}
                </MenuItem>
                <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                          value={'unknown'}>
                  {"unknown"}
                </MenuItem>
                <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                          value={'valid'}>
                                <span style={{color: "rgb(100,169,68)"}}>
                        <DoneTwoTone/>
                    </span>
                </MenuItem>
                <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                          value={'invalid'}>
                               <span style={{color: "rgb(231,92,92)"}}>
                        <CloseTwoTone/>
                    </span>
                </MenuItem>
              </Select>
            </FormControl>,
        }
      ])
    }

    if (show_sim_info) {
      columns.push(
        ...[
          {
            Header: "Sim Customer",
            accessor: "sim_info.value",
            Cell: row => {
              if (row.value === "" || row.value === null || row.value === undefined) {
                return "-"
              }
              return row.value.customer
            },
          },
          {
            Header: "Rate Plan",
            accessor: "sim_info.value",
            width: 180,
            Cell: row => {
              if (row.value === "" || row.value === null || row.value === undefined) {
                return "-"
              }
              return showWarning(row.value.rate_plan, row.value.rate_plan.indexOf("Keep Alive") > -1)
            },
          },
          {
            Header: "Limit Reached",
            accessor: "sim_info.value",
            Cell: row => {
              if (row.value === "" || row.value === null || row.value === undefined) {
                return "-"
              }
              return row.value.overage_limit_reached ? "Yes" : "No"
            },
          },
          {
            Header: "Limit Override",
            accessor: "sim_info.value",
            Cell: row => {
              if (row.value === "" || row.value === null || row.value === undefined) {
                return "-"
              }
              return row.value.overage_limit_override
            },
          }
        ]
      )
    }

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader><h4 style={{color: primaryColor}}>
              <b>{"Overview All Devices"}</b> {last_updated !== null ?
              <p>Last update: <ReactTimeAgo date={last_updated} locale="en-UK"/></p> : ""}</h4>
            </CardHeader>
            <CardBody>
              <Button
                onClick={() => this.setState(prevState => ({
                  prevState,
                  show_device_settings: !prevState.show_device_settings
                }))}
                color={show_device_settings ? "primary" : "github"}
                simple
              >
                Show Device Info
              </Button>
              <Button
                onClick={() => this.setState(prevState => ({
                  prevState,
                  show_software_info: !prevState.show_software_info
                }))}
                color={show_software_info ? "primary" : "github"}
                simple
              >
                Show Software Info
              </Button>
              <Button
                onClick={() => this.setState(prevState => ({
                  prevState,
                  show_map_info: !prevState.show_map_info
                }))}
                color={show_map_info ? "primary" : "github"}
                simple
              >
                Show Map Info
              </Button>
              <Button
                onClick={() => this.setState(prevState => ({
                  prevState,
                  show_sim_info: !prevState.show_sim_info
                }))}
                color={show_sim_info ? "primary" : "github"}
                simple
              >
                Show SIM Info
              </Button>
              <MyReactTable
                id={"settings_overview"}
                data={settings_overview.sort((a, b) => {
                  return a["name"] < b["name"] ? -1 : 1
                })}
                filterable
                columns={columns}
                getTrProps={(state, rowInfo, column) => {
                  if (rowInfo && rowInfo.row) {
                    return {
                      style: {
                        background: rowInfo.row._original.last_seen === "" ? "rgba(255, 0, 0, 0.05)" : null
                      }
                    }
                  } else {
                    return {}
                  }
                }}
                defaultPageSize={10}
                page={settings_page}
                onPageChange={this.on_page_change.bind(this)}
                showPaginationTop={false}
                showPaginationBottom={true}
                className=" -highlight"
                loading={loading}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}


function mapStateToProps(state) {
  const {all_devices} = state.device_fleet
  return {
    all_devices
  }
}

function showWarning(text, show) {
  return (show ? <Alert severity={"warning"}> {text} </Alert> : {text})
}

export default withStyles(extendedTablesStyle)(connect(mapStateToProps)(SettingsFleet))
