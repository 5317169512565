import React from "react"
import {connect} from 'react-redux'
import {filterSubstring} from "../helper_functions/table_filtering";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"
import Card from "components/Card"
import CardBody from "components/CardBody"
import CardHeader from "components/CardHeader"

import extendedTablesStyle from "./extendedTablesStyle.jsx"

import {systemService} from "_services"

import _ from "lodash"
import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import {numberWithSpaces} from "./../helpers"
import MyReactTable from "../../components/MyReactTable/MyReactTable";

import ReactTimeAgo from 'react-time-ago'

class MetricsFleet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      last_updated: null,
      loading: false
    }

    this.timer = null
    this._is_mounted = false
    this._refresh_rate = 1000
  }

  componentDidMount() {
    this._is_mounted = true
    this.setState({loading: true})
    this.refresh()
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this._is_mounted = false
  }

  refresh() {
    systemService.getMetrics(result => {
        let reworked_data = result.result.map(metric => {
          let reworked_metric = {
            "name": metric["name"],
            "mileage": "-",
            "laneswitches": "-",
            "active_time": "-",
            "uv_switches": "-",
            "uv_active_time": "-",
          }

          if (metric["mileage"] && metric["mileage"]["value"] && metric["mileage"]["value"] > 0) {
            reworked_metric["mileage"] = metric["mileage"]["value"]
          }
          if (metric["laneswitches"] && metric["laneswitches"]["value"] && metric["laneswitches"]["value"] > 0) {
            reworked_metric["laneswitches"] = metric["laneswitches"]["value"]
          }
          if (metric["active_time"] && metric["active_time"]["value"] && metric["active_time"]["value"] > 0) {
            reworked_metric["active_time"] = metric["active_time"]["value"]
          }
          if (metric["uv_switches"] && metric["uv_switches"]["value"] && metric["uv_switches"]["value"] > 0) {
            reworked_metric["uv_switches"] = metric["uv_switches"]["value"]
          }
          if (metric["uv_active_time"] && metric["uv_active_time"]["value"] && metric["uv_active_time"]["value"] > 0) {
            reworked_metric["uv_active_time"] = metric["uv_active_time"]["value"]
          }
          return reworked_metric
        })

        this.setState({data: reworked_data, loading: false, last_updated: new Date(result.updated)})

        if (this._is_mounted) {
          this.timer = setTimeout(() => this.refresh(), this._refresh_rate)
        }
      },
      reject => {
        if (this._is_mounted) {
          this.timer = setTimeout(() => this.refresh(), this._refresh_rate * 2)
        }
      }
    )
  }

  get_total(data, name, unit) {
    let filtered_data = data.data.filter(function (el) {
      return !isNaN(el[name])
    })

    let total = _.sum(_.map(filtered_data, d => d[name]))
    total = Math.round((total + Number.EPSILON) * 100) / 100

    total = unit ? total + ' ' + unit : total

    return <span>{numberWithSpaces(total)}</span>
  }

  compareNumericString(rowA, rowB, desc) {
    let a = Number.parseFloat(rowA);
    let b = Number.parseFloat(rowB);
    if (Number.isNaN(a)) {  // Blanks and non-numeric strings to bottom
      a = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
    }
    if (Number.isNaN(b)) {
      b = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
    }
    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
  }

  render() {
    const {data, loading, last_updated} = this.state

    let columns = [
      {
        Header: "Name",
        accessor: "name",
        Cell: row => (<b>{row.value}</b>),
        Footer: info => {
          return "Total"
        }
      },
      {
        Header: "Mileage",
        accessor: "mileage",
        Cell: row => row.value === "-" ? row.value : (numberWithSpaces(Math.round(row.value, 2)) + ' km'),
        filterMethod: (filter, row) => filterSubstring(filter, row),
        sortMethod: this.compareNumericString,
        Footer: info => this.get_total(info, 'mileage', 'km')
      },
      {
        Header: "Lane Switches",
        accessor: "laneswitches",
        Cell: row => numberWithSpaces(row.value, 2),
        filterMethod: (filter, row) => filterSubstring(filter, row),
        sortMethod: this.compareNumericString,
        Footer: info => this.get_total(info, 'laneswitches', '')
      },
      {
        Header: "Active Time",
        accessor: "active_time",
        Cell: row => row.value === "-" ? row.value : (numberWithSpaces(Math.round(row.value, 2)) + ' h'),
        filterMethod: (filter, row) => filterSubstring(filter, row),
        sortMethod: this.compareNumericString,
        Footer: info => this.get_total(info, 'active_time', 'h')
      },
      {
        Header: "UV Switches",
        accessor: "uv_switches",
        Cell: row => numberWithSpaces(row.value),
        filterMethod: (filter, row) => filterSubstring(filter, row),
        sortMethod: this.compareNumericString,
        Footer: info => this.get_total(info, 'uv_switches', '')
      },
      {
        Header: "UV Active Time",
        accessor: "uv_active_time",
        Cell: row => row.value === "-" ? row.value : (numberWithSpaces(Math.round(row.value, 2)) + ' h'),
        filterMethod: (filter, row) => filterSubstring(filter, row),
        sortMethod: this.compareNumericString,
        Footer: info => this.get_total(info, 'uv_active_time', 'h')
      }
    ]

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader><h4 style={{color: primaryColor}}>
              <b>{"Overview All Devices"}</b> {last_updated !== null ?
              <p>Last update: <ReactTimeAgo date={last_updated} locale="en-UK"/></p> : ""}</h4>
            </CardHeader>
            <CardBody>
              <MyReactTable
                id={"metrics"}
                data={data.sort((a, b) => {
                  return a["name"] < b["name"] ? -1 : 1
                })}
                filterable
                columns={columns}
                getTrProps={(state, rowInfo, column) => {
                  if (rowInfo && rowInfo.row) {
                    return {
                      style: {
                        color: rowInfo.row._original.name === "decommissioned" ? "rgb(206,205,205)" : null,
                        fontStyle: rowInfo.row._original.name === "decommissioned" ? "italic" : null,
                      }
                    }
                  } else {
                    return {}
                  }
                }}

                defaultPageSize={10}
                showPaginationTop={false}
                showPaginationBottom={true}
                className=" -highlight"
                loading={loading}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}


function mapStateToProps(state) {
  return {
  }
}


export default withStyles(extendedTablesStyle)(connect(mapStateToProps)(MetricsFleet))
