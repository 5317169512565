import React from "react"
import {Redirect, Route} from "react-router-dom"
import {withCookies} from "react-cookie"
import { withAuth } from "react-oidc-context";

class PrivateRoute extends React.Component {
  render() {
    const {component, location, cookies, auth, ...rest} = this.props
    const logged_in = this.props.auth.user ? true : false
    let Comp = component
    return (
      <Route {...rest} render={() => (
        logged_in
          ? <Comp {...this.props} />
          : <Redirect to={{pathname: '/', state: {from: location}}}/>
      )}/>
    )
  }

}

export default withAuth(withCookies(PrivateRoute))
